(function ($) {
    "use strict";

    // strony tekstowe
    var initialize = function () {
        $("[data-timeline-images='link']").on("click", function (evt) {
            var $this = $(this);
            var wrapper = $this.closest("[data-timeline-images='wrapper']");

            evt.preventDefault();

            $("[data-timeline-images='main']", wrapper).attr("src", $this.attr("href"));
            $this.trigger("blur");
        });

        $("[data-timeline-images='main'],[data-side-images='link']").on("click", function (evt) {
            var $this = $(this);
            var wrapper = $this.closest("[data-timeline-images='wrapper'],[data-side-images='wrapper']");

            evt.preventDefault();

            $("[data-timeline-images='modal'],[data-side-images='modal']", wrapper).modal("show");
        });

        $("[data-timeline-images='close'],[data-side-images='close']").on("click", function (evt) {
            evt.preventDefault();
            $(this).closest("[data-timeline-images='modal'],[data-side-images='modal']").modal("hide");
        });

        $("[data-timeline-images='modal'],[data-side-images='modal']").on("show.bs.modal", function () {
            $("[data-timeline-images='thumbs'],[data-side-images='thumbs']", this).addClass("show");
            $("html").addClass("has-open-modal");
        }).on("shown.bs.modal", function (evt) {
            var modal = $(this);

            $("[data-timeline-images='carousel'],[data-side-images='carousel']", modal).trigger("refresh.owl.carousel");
            $("[data-timeline-images='thumbnails'],[data-side-images='thumbnails']", modal).trigger("refresh.owl.carousel");

            clearTimeout(modal.data("closeThumbsTO"));

            modal.data("closeThumbsTO", setTimeout(function () {
                modal.find("[data-timeline-images='thumbs'],[data-side-images='thumbs']").removeClass("show");
            }, 1500));
        }).on("hidden.bs.modal", function () {
            $("html").removeClass("has-open-modal");
        });

        $("[data-timeline-images='thumbs'],[data-side-images='thumbs']").on("mouseenter", function () {
            var modal = $(this).addClass("show").closest("[data-timeline-images='modal'],[data-side-images='modal']");

            clearTimeout(modal.data("closeThumbsTO"));
        }).on("mouseleave", function () {
            var modal = $(this).closest("[data-timeline-images='modal'],[data-side-images='modal']");

            clearTimeout(modal.data("closeThumbsTO"));

            modal.data("closeThumbsTO", setTimeout(function () {
                modal.find("[data-timeline-images='thumbs'],[data-side-images='thumbs']").removeClass("show");
            }, 1500));
        });

        $("[data-timeline-images='carousel'],[data-side-images='carousel']").each(function () {
            var $this = $(this);

            $this.owlCarousel({
                items: 1,
                margin: 30,
                dots: false,
                nav: true,
                navText: ['', ''],
                navContainer: $this.closest("[data-timeline-images='modal'],[data-side-images='modal']").find("[data-timeline-images='nav'],[data-side-images='nav']").get(0),
                navSpeed: 1500,
                dragEndSpeed: 1000,
                autoplay: false
            }).closest("[data-timeline-images='modal'],[data-side-images='modal']")
                .find("[data-timeline-images='thumbnails'],[data-side-images='thumbnails']").each(function () {
                $(this).on("translated.owl.carousel", function (evt) {
                    $this.trigger("to.owl.carousel", [evt.page.index, 1500]);
                }).owlCarousel({
                    items: 10,
                    margin: 10,
                    dots: false,
                    nav: false,
                    responsive: {
                        0: {
                            items: 3
                        },
                        576: {
                            items: 6
                        }
                    },
                    dragEndSpeed: 1000,
                    autoplay: false
                });
            }).on("click", "[data-timeline-images='goto'],[data-side-images='goto']", function (evt) {
                var slide = $(this).closest(".owl-item");

                evt.preventDefault();

                $this.trigger("to.owl.carousel", [slide.index(), 1500])
            });
        });
    };

    $(document).on("view-initialized", function () {
        var intervalCount = 0;

        var waitInterval = setInterval(function () {
            ++intervalCount;

            if ($("[data-timeline-images='main'],[data-side-images='link']").length > 0 || intervalCount === 120) {
                clearInterval(waitInterval);
                initialize();
            }
        }, 500);
    });

})(jQuery);
